import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Moment from 'react-moment'
import { Layout, Wrapper, SliceZone, SEO, Header, ListingCard, ShareVirality } from '../../components'
// import ListingCat from '../../components/ListingCat'
import website from '../../../config/website'
import {
  Hero,
  HeroInner,
  Headline,
  HeroBrightner,
  StyledWrapper,
  StyledHatIntro,
  StyledHat,
  StyledWrapperImg,
  StyledPost,
  StyledContact,
} from './Elements'
import 'moment-timezone'
import 'moment/locale/fr'
import Img from 'gatsby-image'

const Post = ({ data: { prismicPost, cats, posts }, location }) => {
  const { data } = prismicPost
  const categories = data.categorie.document[0].data.name
  const authors = data.authors.map(c => `${c.author.document[0].data.name}, `)
  // const catef = cats.edges

  return (
    <Layout customSEO>
      <SEO
        title={`${data.title.text} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={data.shortdesc.text}
        banner={data.main.full.url}
        node={prismicPost}
        article
      />
      <Header light />
      <StyledWrapperImg>
        <Img fluid={data.main.full.localFile.childImageSharp.fluid} />
      </StyledWrapperImg>
      <StyledHat>
        <Wrapper style={{borderBottom: '1px solid #F4F4F4', paddingBottom: '2rem'}}>
          <div dangerouslySetInnerHTML={{ __html: data.title.html }} />
          <StyledHatIntro dangerouslySetInnerHTML={{ __html: data.longdesc.html }} />
          <Link to={`${/auteur/}${data.authors[0].author.uid}`}>{authors}</Link> le{' '}
          <Moment locale="fr" format="D MMM YYYY">
            {prismicPost.first_publication_date}
          </Moment>{' '}
          - <Link to={`${/blog/}${data.categorie.uid}`}># {categories}</Link>
          <ShareVirality shareUrl={`${website.url}${location.pathname}`} />
        </Wrapper>
      </StyledHat>
      <StyledPost>
        <SliceZone allSlices={data.body} />
      </StyledPost>
      {/*<ShareVirality shareUrl={`${website.url}${location.pathname}`} />*/}
      <Wrapper style={{paddingTop: '2rem', paddingBottom: '1rem', textAlign: 'center', borderTop: '1px solid #F4F4F4', borderBottom: '1px solid #F4F4F4'}}>
          <h4 style={{ fontWeight: 'bold'}}>Vous souhaitez en savoir d'avantage ?</h4>
          <StyledContact to='/contact'>Contactez-nous !</StyledContact>
      </Wrapper>
      <StyledWrapper style={{ marginTop: '2rem' }}>
        {/*<ListingCat cats={catef} />*/}
        <h3 style={{ marginTop: '2rem' }}>Publications récentes</h3>
        <ListingCard posts={posts.edges} />
        <StyledContact style={{margin: '0 auto 2rem auto', display: 'block', width: 'fit-content'}} to="/blog/">En voir plus</StyledContact>
      </StyledWrapper>
    </Layout>
  )
}

export default Post

Post.propTypes = {
  data: PropTypes.shape({
    prismicPost: PropTypes.object.isRequired,
    // allPrismicCategory: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
    query PostBySlug($uid: String!) {
        prismicPost(uid: { eq: $uid }) {
            uid
            first_publication_date
            last_publication_date
            data {
                title {
                    html
                    text
                }
                shortdesc {
                    text
                    html
                }
                longdesc {
                    text
                    html
                }
                main {
                    full {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1200, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        url
                        copyright
                        alt
                    }
                }
                date(formatString: "DD.MM.YYYY")

                categorie {
                    uid
                    document {
                        data {
                            name
                        }
                    }
                }

                authors {
                    author {
                        uid
                        id
                        document {
                            data {
                                name
                                image {
                                    url
                                }
                                bio {
                                    html
                                    text
                                }
                            }
                        }
                    }
                }
                body {
                    ... on PrismicPostBodyText {
                        slice_type
                        id
                        primary {
                            text {
                                html
                            }
                        }
                    }
                    ... on PrismicPostBodyQuote {
                        slice_type
                        id
                        primary {
                            quote {
                                html
                                text
                            }
                        }
                    }
                    ... on PrismicPostBodyVideo {
                        slice_type
                        id
                        primary {
                            video {
                                html
                            }
                        }
                    }
                    ... on PrismicPostBodyImage {
                        slice_type
                        id
                        primary {
                            image {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 600, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                                alt
                                copyright
                            }
                        }
                    }
                    ... on PrismicPostBodyImagefull {
                        slice_type
                        id
                        primary {
                            image {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 1200, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                                alt
                                copyright
                            }
                        }
                    }
                    ... on PrismicPostBodyEmbedded {
                        id
                        primary {
                            content {
                                html
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPostBodyArticleblog {
                        slice_type
                        id
                        primary {
                            direction
                            title1 {
                                html
                            }
                            text {
                                html
                            }

                            image {
                                carre {
                                    localFile {
                                        childImageSharp {
                                            fluid( maxWidth: 200, maxHeight: 200, quality: 90) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicPostBodySeparateur {
                        id
                        primary {
                            color
                            type
                        }
                        slice_type
                    }
                    ... on PrismicPostBodyBigbanner {
                        slice_type
                        id
                        primary {
                            text {
                                html
                            }
                            bannertitle {
                                html
                                text
                            }
                            align
                            theme
                            bgcolor
                            image {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 1960, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicPostBodyBouton {
                        id
                        primary {
                            color
                            label
                            link {
                                url
                                target
                            }
                        }
                        slice_type
                    }
                }
            }
        }
        posts: allPrismicPost(limit: 3, sort: { fields: [first_publication_date], order: DESC }, filter: { uid: { ne: "dummy", nin: [$uid] } }) {
            edges {
                node {
                    uid
                    first_publication_date
                    last_publication_date
                    data {
                        title {
                            text
                        }
                        shortdesc {
                            text
                        }
                        main {
                            full {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 1200, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                        date(formatString: "DD.MM.YYYY")
                        categorie {
                            document {
                                uid
                                data {
                                    name
                                    color
                                }
                            }
                        }
                        authors {
                            author {
                                id
                                uid
                                document {
                                    data {
                                        name
                                        image {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
#        cats: allPrismicCategory(filter: { uid: { ne: "dummy" } }, sort: { fields: [data___name], order: ASC }) {
#            edges {
#                node {
#                    data {
#                        body {
#                            id
#                        }
#                        color
#                        name
#                    }
#                    uid
#                }
#            }
#        }
    }
`
